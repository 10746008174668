import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as euiVars from '@elastic/eui/dist/eui_theme_light.json';
import { ThemeProvider } from 'styled-components';

ReactDOM.render(
    <React.StrictMode><ThemeProvider theme={euiVars}><App /></ThemeProvider></React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
