import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Switch, } from 'react-router-dom';

const MacroContent = React.lazy(() => import('./content/macro-content'));
const MacroContentViewEditor = React.lazy(() => import('./settings/macro-content-view-editor'));

export const MacroRouting = () => {
    return (
        <Router>
            <Switch>
                <Suspense fallback={<div className="euiProgress euiProgress--indeterminate euiProgress--xs euiProgress--primary"/>}>
                    <Route exact path="/macro/content" component={MacroContent} />
                    <Route exact path="/macro/view-editor" component={MacroContentViewEditor} />
                </Suspense>
            </Switch>
        </Router>
    );
};