import React from 'react';
import './App.css';
import { MacroRouting } from './modules/macro/macro-routing';

const App = () => {
  return (
    <div className="App ac-content">
      <MacroRouting/>
    </div>
  );
}

export default App;
